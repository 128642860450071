import React from "react";
import { Outlet, Link } from "react-router-dom";

const ImageTextSection = (props) => {
    return (
        <section className="info-section">
            <div className="info-div">
                <header className={props.headerClass}>
                    <div className="info-text">
                        <div className="info-heading">
                            <h1 className="heading">{props.heading}</h1>
                        </div>
                        <div className="info-paragraph">
                            <p>{props.paragraph}</p>
                        </div>
                        <div className="info-clickables">
                            <Link to={props.linkTo}>
                                <div className="about-button">
                                    {props.buttonText}
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="info-image">
                        <img src={props.src} className={props.imgClass} />
                    </div>
                </header>
            </div>
        </section>
    );
};

export default ImageTextSection;
