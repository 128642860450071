import React from "react";

const FoodWin = () => {
    return (
        <>
            <h1 className="cvh">FoodWin</h1>
            <p></p>
        </>
    );
};

export default FoodWin;
